/* Globale Einstellungen */
html,
body {
  margin-top: 20px;
}

/* Container-Stile */
.divCDRootContainer {
  width: 95vw; /* Volle Breite des Viewports */
  height: 95vh; /* Volle Höhe des Viewports */
  display: flex;
  flex-direction: column;
}

.divCDTopContent {
  flex: 0 0 auto;
  overflow: hidden;
}

.divCDMainContent {
  display: flex;
  flex: 1 1 auto;
  overflow: auto;
  justify-content: space-between;
}

.divCDMainLeftContent,
.divCDMainRightContent {
  width: 15%;
  flex: 1 1 auto;
  overflow: hidden;
}

.divCDMainCenterContent {
  width: 65%;
  flex: 1 1 auto;
  overflow: auto;

  scrollbar-width: none; /* Für Firefox */
  -ms-overflow-style: none; /* Für Internet Explorer und Edge */

  /* Webkit-basierten Browsern wie Chrome, Safari */
  &::-webkit-scrollbar {
    display: none;
  }
}

.divCDRootContainer #divider {
  margin-top: 10px;
  margin-bottom: 10px;
  flex: 0 0 auto;
  width: 100%;
}

.hlRequestData,
.hlHitResult,
.hlCommentary {
  color: #003671;
  font-weight: bold;
  font-size: 14pt !important;
  font-family: "Overpass", sans-serif !important;
}

.divCDMainAccordion {
  width: 100%;
}
