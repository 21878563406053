.divOverviewActionContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 5px;
  flex: 0 0 auto;
  width: 100%;
}

.divOverviewActionContainer .acComboBox {
  display: flex;
  gap: 5px;
}

.divOverviewActionContainer .acButtons {
  display: flex;
  gap: 10px !important;
}

.acButtons button {
  border-radius: 5px;
  padding: 3px;
}
.pdfIcon {
  font-size: 19pt !important;
  color: rgb(220, 53, 69) !important;
}

.rtfIcon {
  font-size: 19pt !important;
  color: #003671;
}

.uploadIcon {
  font-size: 19pt !important;
  color: #229954;
}

.settingsOutlinedIcon .MuiSvgIcon-root {
  font-size: 18pt !important;
  color: #003671;
}

.acButtons .pdfIcon:hover {
  background-color: #f3f9ff;
}

.acFormControl {
  width: 200px;
}

.acFormControl .MuiInputBase-root {
  font-size: 9pt;
  height: 33px;
}

.acFormControl .MuiInputLabel-root {
  font-size: 9pt;
  top: -7px;
}

.acFormControl .MuiSelect-icon {
  font-size: 11pt;
}

#acSelectBoxItems {
  font-size: 9pt;
}

.cmdSetAction {
  color: #003671 !important;
  font-size: 8pt !important;
  font-family: "Overpass", sans-serif !important;
  text-transform: none !important;
}

.cmdSetAction .cmdSetActionIcon {
  color: #229954;
}

.uploadExcel {
  display: none;
}

/* Style for the progress bar container */
.excelLoadingContainer {
  position: fixed;
  bottom: 20px; /* Distance from the bottom edge */
  left: 20px; /* Distance from the left edge */
  background-color: #f5f5f5; /* Light background */
  border: 1px solid #ccc; /* Light border */
  border-radius: 4px; /* Rounded corners */
  padding: 16px; /* Space around the content */
  width: 250px; /* Adjust width as needed */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Shadow for better visibility */
  z-index: 1000; /* Ensure it is above other content */
}

/* Style for the progress bar */
.excelProgressBarWrapper {
  margin-bottom: 8px; /* Space between the progress bar and text */
}

/* Full-width progress bar */
.excelProgressBar {
  width: 100%;
}

/* Style for the progress text */
.excelProgressText {
  font-size: 14px; /* Font size */
  color: #333; /* Dark text color for contrast */
}
