.divOnlineFormHeaderRootContainer {
    font-family: "Overpass", sans-serif;
    padding: 0;
    display: flex;
    height: 100%;
    overflow: hidden; /* Verhindert das Scrollen des gesamten HTML/Bodys */
    flex-direction: column;
    place-items: center;
}

.divOnlineFormHeader,
.divOnlineFormHeaderTextOne,
.divOnlineFormHeaderTextTwo {
    text-align: center;          /* Center the text in the divs */
    margin: 10px auto;          /* Center the divs horizontally and add vertical space */
    color: #003671;
    max-width: 100%;            /* Allow the first div to use full width */
}

.divOnlineFormHeader {
    font-size: 26px !important; /* Set font size for the first div */
    font-weight: bold;          /* Make the first div bold */
}

.divOnlineFormHeaderTextOne, .divOnlineFormHeaderTextTwo {
    font-weight:  bold;
    font-size: 11px !important; /* Set font size for the second div */
    white-space: nowrap;        /* Prevent text from wrapping */
    overflow: hidden;           /* Hide any overflow */
    text-overflow: ellipsis;    /* Show ellipsis if the text overflows */
}