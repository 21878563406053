.divOverviewButtonContainer {
  margin-bottom: 20px;
  display: flex;
  gap: 5px;
  flex: 0 0 auto;
  width: 100%; /* Breite des Button Containers auf 100% setzen */
}

.divOverviewButtonContainer button {
  background-color: white;
  color: #003671;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 11pt !important;
  padding: 0;
  margin-right: 8px;
}

.addIconButton .MuiSvgIcon-root,
.filterIconButton .MuiSvgIcon-root,
.cachedIconButton .MuiSvgIcon-root {
  font-size: 20pt !important;
  color: #003671;
}

.divOverviewButtonContainer .textButtons {
  display: flex;
  background-color: #f8f8f8;
  align-items: center;
  border-radius: 6px;
  color: #4a4d50;
  font-size: 10pt;
}

.divOverviewButtonContainer .textButtonsIcon {
  margin-left: 3px;
  color: red;
  font-size: 11pt;
}

.divOverviewButtonContainer button:hover {
  background-color: #f3f9ff;
}

.filterCloseButton {
  color: red;
}

.filterIconContainer {
  display: inline-block;
}

.filterCounter {
  background-color: #ef7b10;
  color: white;
  padding: 3px;
  border-radius: 5px;
  font-size: 6pt !important;
  text-align: center;
  position: relative;
  top: -17px;
  left: -8px;
}

/* filter container block */
.divOverviewFilterContainer {
  display: flex;
  margin-bottom: 20px;
  gap: 5px;
  flex: 0 0 auto;
  width: 100%; /* Breite des Filter Containers auf 100% setzen */
}

.divOverviewFilterContainer button {
  background-color: white;
  color: #003671;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 11pt;
  padding: 5px;
  align-items: center;
}

.divOverviewFilterContainer button:hover {
  background-color: #f3f9ff;
}

.fcDatePickers {
  width: 160px;
}

.fcDatePickers .MuiInputBase-input,
.fcDatePickers .MuiInputLabel-root,
.fcFormControl .MuiInputBase-root,
.fcFormControl .MuiInputLabel-root {
  font-size: 9pt;
  font-family: "Overpass", sans-serif;
  height: 33px;
}

.fcDatePickers .MuiInputBase-input {
  height: 0;
}

.fcDatePickers .MuiInputLabel-root,
.fcFormControl .MuiInputLabel-root {
  top: -7px;
}

.fcDatePickers .MuiSvgIcon-root,
.fcFormControl .MuiSelect-icon {
  font-size: 11pt;
}

.fcFormControl {
  width: 200px;
}

#fcSelectBoxItems {
  font-size: 9pt;
}

.cmdSetFilter{
  color: #003671;
  font-size: 8pt !important;
  font-family: "Overpass", sans-serif !important;
  text-transform: none !important;
}

.cmdCloseFilter{
  color: #003671;
  font-size: 8pt !important;
  font-family: "Overpass", sans-serif !important;
  text-transform: none !important;
}

.cmdSetFilter .cmdSetFilterIcon .cmdCloseFilter .cmdCloseFilterIcon{
  color: #229954;
}
.cmdCloseFilter .cmdCloseFilterIcon{
  color: #d21f1f;
}