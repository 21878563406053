/*<editor-fold desc="== General container section ==">*/
html,
body {
  font-family: "Overpass", sans-serif;
  padding: 0;
  display: flex;
  height: 100%;
  overflow: hidden; /* Verhindert das Scrollen des gesamten HTML/Bodys */
  flex-direction: column;
  place-items: center;
}

.divOverviewContainer {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Höhe auf die gesamte Ansichtshöhe setzen */
  overflow: auto; /* Verhindert das Scrollen des Containers */
  width: 100%; /* Breite des Containers auf 100% setzen */

}
/*</editor-fold>*/

/*<editor-fold desc="== Tabs container section ==">*/
.divOverviewTabBar {
  margin-bottom: 20px;
  flex: 0 0 auto;
  width: 100%; /* Breite des Tab Containers auf 100% setzen */
}
/*</editor-fold>*/

/*<editor-fold desc="== Filter container section ==">*/
.divOverviewFilterContainer {
  display: flex;
  margin-bottom: 20px;
  gap: 5px;
  flex: 0 0 auto;
  width: 100%; /* Breite des Filter Containers auf 100% setzen */
}

.divOverviewFilterContainer button {
  background-color: white;
  color: #003671;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 11pt;
  padding: 5px;
  align-items: center;
}

.divOverviewFilterContainer button:hover {
  background-color: #f3f9ff;
}

.fcDatePickers {
  width: 160px;
}

.fcDatePickers .MuiInputBase-input,
.fcDatePickers .MuiInputLabel-root,
.fcFormControl .MuiInputBase-root,
.fcFormControl .MuiInputLabel-root {
  font-size: 9pt;
  font-family: "Overpass", sans-serif;
  height: 33px;
}

.fcDatePickers .MuiInputBase-input {
  height: 0;
}

.fcDatePickers .MuiInputLabel-root,
.fcFormControl .MuiInputLabel-root {
  top: -7px;
}

.fcDatePickers .MuiSvgIcon-root,
.fcFormControl .MuiSelect-icon {
  font-size: 11pt;
}

.fcFormControl {
  width: 200px;
}

#fcSelectBoxItems {
  font-size: 9pt;
}

.divOverviewFilterContainer .fcFilterApprovalIcon {
  font-size: 18pt;
  color: green;
}
/*</editor-fold>*/

/*<editor-fold desc="== Action container section ==">*/
.divOverviewActionContainer {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Inhalt gleichmäßig verteilen, Buttons nach rechts schieben */
  margin-bottom: 20px;
  gap: 5px;
  flex: 0 0 auto;
  width: 100%; /* Breite des Containers auf 100% setzen */
}

.divOverviewActionContainer .acComboBox {
  display: flex;
  gap: 5px;
}

.divOverviewActionContainer .acButtons {
  display: flex;
  gap: 5px;
  margin-left: auto; /* Schiebt die Buttons nach rechts */
}

.acButtons button {
  background-color: transparent;
  border: none;
  padding: 0;
}

.acButtons img {
  width: 24px;
  height: 24px;
}

.acFormControl {
  width: 200px;
}

.acFormControl .MuiInputBase-root {
  font-size: 9pt;
  height: 33px;
}

.acFormControl .MuiInputLabel-root {
  font-size: 9pt;
  top: -7px;
}

.acFormControl .MuiSelect-icon {
  font-size: 11pt;
}

#acSelectBoxItems {
  font-size: 9pt;
}
/*</editor-fold>*/

/*<editor-fold desc="== Footer container section ==">*/
.divOverviewFooterContainer {
  width: 100%;
  margin-top: 10px;
}
/*</editor-fold>*/
