.divCDMenuContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.buttonElements {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 15vw;
}

.actionElements {
    display: flex;
    width: 70vw;
    gap: 15px;
    justify-content: center;
}

.reportElements {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 15vw;
}

.formControls, .MuiInputBase-root, .MuiInputLabel-root, .MuiMenuItem-root {
    font-size: 10pt !important;
    font-family: 'Overpass', sans-serif !important;
}

.formControls {
    width: 200px !important;
}

.noBorders .MuiOutlinedInput-root {
    border: none;
}

.noBorders .MuiOutlinedInput-notchedOutline {
    border: none;
}

.noBorders:hover .MuiOutlinedInput-notchedOutline {
    border: none;
}

.noBorders .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none;
}

.cmdBackToOverview .MuiSvgIcon-root{
    font-size: 17pt !important;
    color: #003671;
}

.cmdBackToOverview:hover {
    border: none;
    border-radius: 6px;
    background-color: rgb(250, 250, 250);
}

.cmdCreateRTFReport {
    border: none;
    border-radius: 6px !important;
    background-color: rgb(250, 250, 250);
}

.cmdCreateRTFReport .MuiSvgIcon-root{
    font-size: 17pt !important;
    color: #003671;
}

.cmdCreatePDFReport {
    border: none;
    border-radius: 6px !important;
    background-color: rgb(250, 250, 250);
}

.cmdCreatePDFReport .MuiSvgIcon-root{
    font-size: 17pt !important;
    color: rgb(220,53,69)  !important;
}

#selectItems {
    font-size: 10pt !important;
    font-family: 'Overpass', sans-serif !important;
}
