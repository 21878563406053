* {
  font-family: "Overpass", sans-serif !important;
  font-size: 11pt !important;
}

.hlProductHitList {
  color: #003671;
  font-weight: bold;
  font-size: 12pt !important;
}

.productHitListItem {
  background-color: rgb(250, 250, 250) !important;
  margin-top: 2px;
  width: 100% !important;
  padding: 0;
}

.productHitListItem .productHitListItemText .MuiTypography-root {
  font-weight: bold !important;
}

.productHitList .MuiListItem-root :hover {
  background-color: rgb(250, 250, 250) !important;
}

.productMatch {
  background-color: #003671 !important;
  font-size: 10pt !important;
  font-family: "Overpass", sans-serif !important;
  text-transform: none !important;
}

.anfrageAktualisieren {
  color: #003671;
  font-size: 8pt !important;
  font-family: "Overpass", sans-serif !important;
  text-transform: none !important;
}
