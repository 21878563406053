* {
  font-family: "Overpass", sans-serif !important;
  font-size: 11pt !important;
}

.hlDocumentUploader {
  color: #003671;
  font-weight: bold;
  font-size: 12pt !important;
}

.documentList {
  background-color: rgb(250, 250, 250) !important;
  margin-top: 2px;
}

.documentList .documentListItem .MuiSvgIcon-root {
  color: #003671;
  font-size: 12pt !important;
}

.documentList .documentListDeleteButton .MuiSvgIcon-root {
  color: rgb(220, 53, 69) !important;
  font-size: 12pt !important;
}

.cmdAddDocument {
  color: #003671;
  font-size: 8pt !important;
  font-family: "Overpass", sans-serif !important;
  text-transform: none !important;
}

.fileSizeNote {
  color: #003671;
  font-size: 8pt !important;
  font-family: "Overpass", sans-serif !important;
  text-transform: none !important;
}

.inputFieldFileUpload {
  display: none;
}

.docFormControls,
.MuiInputBase-root,
.MuiInputLabel-root,
.MuiMenuItem-root {
  font-size: 10pt !important;
  font-family: "Overpass", sans-serif !important;
}

.docFormControls {
  width: 100% !important;
  margin-bottom: 5px !important;
}

#selectItemsContentType {
  font-size: 10pt !important;
  font-family: "Overpass", sans-serif !important;
}

.clickableFile {
  cursor: pointer;
}

.download-link {
  display: block;
  margin: 20px;
}

.scrollableListContainer {
  max-height: 300px; /* Adjust height as needed */
  overflow-y: auto;
  padding: 8px; /* Optional: adds padding for better appearance */
}

