* {
  font-family: "Overpass", sans-serif !important;
  font-size: 11pt !important;
  box-sizing: border-box;
}

.addComment,
.displayComments {
  display: flex;
  gap: 10px;
  padding-top: 10px;
  font-family: "Overpass", sans-serif !important;
  flex: 1;
  justify-content: flex-end;
}

.newComment {
  margin-bottom: 10px;
}

.addComment {
  border-top: 1px solid;
  border-color: #d6d8da;
}

.displayComments {
  border-bottom: 1px solid;
  border-color: #d6d8da;
}

.cmdAddComment {
  font-size: 10pt !important;
  font-weight: normal !important;
}

.commentUser,
.commentDate {
  font-family: "Overpass", sans-serif !important;
  font-size: 8pt !important;
  padding-left: 1px;
}

.commentUser {
  flex: 1;
  justify-content: flex-start;
}

.commentDate {
  flex: 0;
  justify-content: flex-end;
}

.textAreaComments {
  margin-bottom: 10px;
  font-family: "Overpass", sans-serif !important;
  font-size: 10pt !important;
  color: #003671;
}
